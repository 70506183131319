<template>
  <base-layout>
    <view-subscription-plan />
    <add-edit-subscription-plan />
    <content-section :spacing="false">
      <div class="content-spacing">
        <title-plus
          title="Subscription Plans"
          :hide-plus="!$user.is_superadmin"
          @plus="add"
          class="mb-10"
        />

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>{{ item.id | onlyLastFive }}</fsTableRowItem>
            <fsTableRowItem>
              <div
                @click="view(item)"
                class="font-semibold text-blue-700 cursor-pointer"
              >
                {{ item.plan_name }}
              </div>
            </fsTableRowItem>
            <fsTableRowItem>{{
              item.created_at | friendlyDate
            }}</fsTableRowItem>
            <fsTableRowItem>{{
              item.updated_at | friendlyDate
            }}</fsTableRowItem>
            <fsTableRowItem>{{ symbol }}{{ item.price }}/Month </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <template v-if="$user.is_superadmin">
                  <oto-edit-icon @click="edit(item)" />
                  <MoreActionsDropdown
                    :currentVal="item.status_active"
                    onOffMethod="PATCH"
                    :onOffEndpoint="getOnOffEndpoint(item.id)"
                    actionOnKey="status_active"
                    :actionOnVal="true"
                    actionOffKey="status_active"
                    :actionOffVal="false"
                    actionDeleteKey="deleted_at"
                    actionDeleteVal="now"
                    actionDeleteMethod="PATCH"
                    :actionDeleteEndpoint="getOnOffEndpoint(item.id)"
                    :actionDeleteEnabled="true"
                  />
                </template>
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>
        <!-- fs-table-footer -->
        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
        <!-- fs-table-footer -->
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoSearch from '@/components/ui/OtoSearch'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'
// import DateRangePicker from "@/components/picker/date-range/DateRangePicker";

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { SubscriptionConfig } from '@/config/SubscriptionConfig'
import { EventBus } from '@/utils/EventBus'

import AddEditSubscriptionPlan from '@/views/pricing/SubscriptionPlanAddEdit'
import ViewSubscriptionPlan from '@/views/pricing/SubscriptionPlanDetails'
import { mapGetters } from 'vuex'

export default {
  name: 'PricingPlans',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    ExportAsDropdown,
    OtoSearch,
    OtoEditIcon,
    // DateRangePicker,
    MoreActionsDropdown,
    AddEditSubscriptionPlan,
    ViewSubscriptionPlan,
  },
  data() {
    return {
      symbol: '$',
      indexDataEndpoint: SubscriptionConfig.api.index,
      tableHeaders: [
        {
          name: 'ID',
          width: '10%',
          field: 'id',
          sort: false,
          order: '',
        },
        {
          name: 'Name',
          width: '19%',
          field: 'name',
          sort: false,
          order: '',
        },
        {
          name: 'Created At',
          width: '18%',
          field: 'created_at',
          sort: false,
          order: '',
        },
        {
          name: 'Updated At',
          width: '18%',
          field: 'updated_at',
          sort: false,
          order: '',
        },
        {
          name: 'Price',
          width: '15%',
          field: 'price',
          sort: false,
          order: '',
        },
        {
          name: 'Actions',
          width: '15%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    window.addEventListener(SubscriptionConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    add: function() {
      EventBus.$emit(SubscriptionConfig.events.editingData, {
        // enable status-switch by default
        point_management_enabled: true,
        end_ride_photo_enabled: true,
        group_trip_enabled: true,
        rental_enabled: true,
        operator_app_enabled: true,
        marketplace_fleet_enabled: true,
        service_area_swapping_enabled: true,
        is_file_export_enabled: true,
        pass_management_enabled: true,
        ticket_management_enabled: true,
        should_use_otoride_email_credentials: true,
        status_active: true,
      })
      dispatchEvent(new Event(SubscriptionConfig.events.sorToggle))
      console.log('asdfdsaf')
    },
    edit: function(item) {
      EventBus.$emit(SubscriptionConfig.events.editingData, item)
      dispatchEvent(new Event(SubscriptionConfig.events.sorToggle))
    },
    view: function(item) {
      EventBus.$emit(SubscriptionConfig.events.viewingData, item)
      this.$modal.show(SubscriptionConfig.events.viewingData)
    },
    getOnOffEndpoint(id) {
      console.log(SubscriptionConfig.api.update(id))
      return SubscriptionConfig.api.update(id)
    },
  },
}
</script>

<style></style>
