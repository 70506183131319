<template>
  <slide-over-right :id="sorId" class="vld-parent" edit-type="roles">
    <loading :active.sync="isLoading" :is-full-page="false"></loading>

    <template v-slot:header>
      <div class="flex justify-between items-center">
        <h2 class="font-semibold text-gray-900 text-22px">
          {{ titleText }}
        </h2>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex justify-end items-center mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button
          type="submit"
          @click="$refs.submitButton.click()"
          class="ml-3"
          >{{ actionButtonText }}</t-button
        >
      </div>
    </template>

    <div v-if="errors.length > 0" class="pl-6 text-red-500">
      Error returned from server
    </div>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" class="px-6">
        <text-input
          rules="required"
          label="Plan name"
          v-model="form.plan_name"
        />
        <div class="flex items-center">
          <div class="block pr-2 w-1/2">
            <!-- <div class="-mt-4 font-semibold text-14px">
              Billing Type *
            </div> -->
            <!-- <div class="w-1/2"> -->
            <ValidationProvider
              name="Billing type"
              rules="required"
              v-slot="{ errors }"
            >
              <!-- <label class="flex items-center mt-px ml-2 cursor-pointer"> -->
              <input-label :error="errors[0]" text="Billing Type *" />
              <t-rich-select
                name="options"
                :options="billingTypeChoice"
                valueAttribute="value"
                textAttribute="name"
                :hideSearchBox="true"
                v-model="form.billing_type"
              />
              <!-- <span class="ml-2 text-sm">Vehicle/Month</span> -->
              <!-- </label> -->
              <!-- <div class="mt-4"> -->
              <input-error-item :message="errors[0]" />
              <!-- </div> -->
            </ValidationProvider>
            <!-- </div> -->
          </div>

          <div class="block pl-2 w-1/2">
            <text-input
              rules="required|between:0,1000"
              label="Price"
              v-model="form.price"
            />
          </div>
        </div>

        <div v-if="form.billing_type === 'VEHICLE/MONTH'">
          <text-input
            label="Minimum Monthly Subscription Fee"
            rules="required"
            v-model="form.minimum_fee"
          />
        </div>
        <div class="pb-5 feature-item">
          <div class="text-sm font-semibold">
            Feature
          </div>
          <div class="text-sm font-semibold">
            Access
          </div>
        </div>

        <div class="feature-item -pb-1">
          <div class="mt-3 left">
            Number of vehicles *
          </div>
          <div class="right">
            <text-input
              vid="num_of_vehicles"
              name=" "
              rules="required|between:0,9999"
              v-model="form.num_of_vehicles"
            />
          </div>
        </div>

        <div class="feature-item -pb-1">
          <div class="mt-3 left">
            Number of fleets *
          </div>
          <div class="right">
            <text-input
              vid="num_of_fleets"
              name=" "
              rules="required|between:0,1000"
              v-model="form.num_of_fleets"
            />
          </div>
        </div>

        <div class="feature-item -pb-1">
          <div class="mt-3 left">
            Number of promo codes *
          </div>
          <div class="right">
            <text-input
              vid="num_of_promo_codes"
              name=" "
              rules="required|between:0,1000"
              v-model="form.num_of_promo_codes"
            />
          </div>
        </div>

        <div class="feature-item -pb-1">
          <div class="mt-3 left">
            Number of org user accounts *
          </div>
          <div class="right">
            <text-input
              vid="num_of_account"
              name=" "
              rules="required|between:0,1000"
              v-model="form.num_of_org_user_accounts"
            />
          </div>
        </div>

        <div class="feature-item">
          <div class="left">Currency *</div>
          <div class="right">
            <ValidationProvider
              vid="multi_currency_enabled"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Single"
                  :value="false"
                  name="multi_currency_enabled"
                  v-model="form.multi_currency_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Multiple"
                  :value="true"
                  name="multi_currency_enabled"
                  v-model="form.multi_currency_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>

        <div class="feature-item">
          <div class="left">Language *</div>
          <div class="right">
            <ValidationProvider
              vid="multi_language_enabled"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Single"
                  :value="false"
                  name="multi_language_enabled"
                  v-model="form.multi_language_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Multiple"
                  :value="true"
                  name="multi_language_enabled"
                  v-model="form.multi_language_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>

        <div class="feature-item">
          <div class="left">Geofence *</div>
          <div class="right">
            <ValidationProvider
              vid="mult_igeofence_enabled"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Single"
                  :value="false"
                  name="multi_geofence_enabled"
                  v-model="form.multi_geofence_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Multiple"
                  :value="true"
                  name="multi_geofence_enabled"
                  v-model="form.multi_geofence_enabled"
                />
              </div>

              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>

        <div
          class="feature-item"
          style="padding-bottom: 25px; padding-top: 8px;"
        >
          <div class="flex items-center geofence-cat-left">
            Geofence Category
          </div>
          <div class="flex justify-end items-end right geofence-cat-right">
            <div
              class="grid grid-cols-1 place-content-end w-full md:grid-cols-2"
            >
              <t-checkbox
                wrapped
                variant="lightweight"
                label="Service Area"
                :name="`geofence_categories_service`"
                v-model="form.geofence_categories_service"
              />

              <t-checkbox
                wrapped
                variant="lightweight"
                label="Parking Area"
                :name="`geofence_categories_parking`"
                v-model="form.geofence_categories_parking"
              />

              <t-checkbox
                wrapped
                variant="lightweight"
                label="Slow Zone"
                name="geofence_categories_slow_zone"
                v-model="form.geofence_categories_slow_zone"
              />
              <t-checkbox
                wrapped
                variant="lightweight"
                label="Restricted Zone"
                name="options"
                v-model="form.geofence_categories_restricted"
              />
            </div>
          </div>
        </div>

        <div class="feature-item">
          <div class="left">Point Management *</div>
          <div class="right">
            <ValidationProvider
              vid="point_management_enabled"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="point_management_enabled"
                  v-model="form.point_management_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="point_management_enabled"
                  v-model="form.point_management_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>

        <div class="feature-item">
          <div class="left">End Ride Photo *</div>
          <div class="right">
            <ValidationProvider
              vid="end_ride_photo"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="end_ride_photo_enabled"
                  v-model="form.end_ride_photo_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="end_ride_photo_enabled"
                  v-model="form.end_ride_photo_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>

        <div class="feature-item">
          <div class="left">Group Trips Enabled *</div>
          <div class="right">
            <ValidationProvider
              vid="group_trip_enabled"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="group_trip_enabled"
                  v-model="form.group_trip_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="group_trip_enabled"
                  v-model="form.group_trip_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>

        <div class="feature-item">
          <div class="left">Vehicle Rental Enabled *</div>
          <div class="right">
            <ValidationProvider
              vid="rental_enabled"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="rental_enabled"
                  v-model="form.rental_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="rental_enabled"
                  v-model="form.rental_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>

        <div class="feature-item">
          <div class="left">Access to Operator App *</div>
          <div class="right">
            <ValidationProvider
              vid="operator_app_enabled"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="operator_app_enabled"
                  v-model="form.operator_app_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="operator_app_enabled"
                  v-model="form.operator_app_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <div class="feature-item">
          <div class="left">Marketplace Fleet Enabled *</div>
          <div class="right">
            <ValidationProvider
              vid="marketplace_fleet_enabled"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="marketplace_fleet_enabled"
                  v-model="form.marketplace_fleet_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="marketplace_fleet_enabled"
                  v-model="form.marketplace_fleet_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <div class="feature-item">
          <div class="left">Service Area Swapping *</div>
          <div class="right">
            <ValidationProvider
              vid="service_area_swapping_enabled"
              name=" "
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="service_area_swapping_enabled"
                  v-model="form.service_area_swapping_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="service_area_swapping_enabled"
                  v-model="form.service_area_swapping_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <div class="feature-item">
          <div class="left">File Export</div>
          <div class="right">
            <ValidationProvider
              vid="is_file_export_enabled"
              name=""
              rules=""
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="is_file_export_enabled"
                  v-model="form.is_file_export_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="is_file_export_enabled"
                  v-model="form.is_file_export_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <div class="feature-item">
          <div class="left">Enable Pass Management</div>
          <div class="right">
            <ValidationProvider
              vid="pass_management_enabled"
              name=""
              rules=""
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="pass_management_enabled"
                  v-model="form.pass_management_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="pass_management_enabled"
                  v-model="form.pass_management_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <div class="feature-item">
          <div class="left">Enable Ticket Management</div>
          <div class="right">
            <ValidationProvider
              vid="ticket_management_enabled"
              name=""
              rules=""
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="ticket_management_enabled"
                  v-model="form.ticket_management_enabled"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="ticket_management_enabled"
                  v-model="form.ticket_management_enabled"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <div class="feature-item">
          <div class="left">Use Otoride Default Email Credential</div>
          <div class="right">
            <ValidationProvider
              vid="should_use_otoride_email_credentials"
              name=""
              rules=""
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Yes"
                  :value="true"
                  name="should_use_otoride_email_credentials"
                  v-model="form.should_use_otoride_email_credentials"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="No"
                  :value="false"
                  name="should_use_otoride_email_credentials"
                  v-model="form.should_use_otoride_email_credentials"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <div class="feature-item">
          <div class="left">Subscription Status</div>
          <div class="right">
            <ValidationProvider
              vid="status_active"
              name=""
              rules=""
              v-slot="{ errors }"
            >
              <div class="flex">
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Active"
                  :value="true"
                  name="status_active"
                  v-model="form.status_active"
                />
                <t-radio
                  wrapped
                  variant="lightweight"
                  label="Inactive"
                  :value="false"
                  name="status_active"
                  v-model="form.status_active"
                />
              </div>
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <div
          class="feature-item"
          style="padding-bottom: 25px; padding-top: 8px;"
        >
          <div class="left">Reporting Category</div>
          <div class="right">
            <div class="flex w-full">
              <t-checkbox
                wrapped
                variant="lightweight"
                label="MDS"
                :name="`reporting_categories_mds`"
                v-model="form.reporting_categories_mds"
              />

              <t-checkbox
                wrapped
                variant="lightweight"
                label="GBFS"
                :name="`reporting_categories_gbfs`"
                v-model="form.reporting_categories_gbfs"
              />
            </div>
          </div>
        </div>

        <button type="submit" ref="submitButton" class="hidden">Save</button>
      </form>
    </ValidationObserver>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { SubscriptionConfig } from '@/config/SubscriptionConfig'

import SlideOverRight from '@/components/modals/SlideOverRight'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'
import TextInput from '../../components/form/TextInput.vue'
import InputLabel from '@/components/form/InputLabel'
// import ConfirmModal from "@/components/modals/ConfirmModal";

export default {
  name: 'AddTaxPlan',
  components: {
    SlideOverRight,
    SlideOverRightTrigger,
    AnchorButton,
    TextInput,
    InputLabel,
    // ConfirmModal
  },
  data() {
    return {
      isLoading: false,
      item: {},
      form: {},
      errors: [],
      sorId: SubscriptionConfig.events.sorId,
    }
  },
  computed: {
    isEditing: function() {
      // enabled status-switch by default
      return !!this.item.id
    },
    titleText: function() {
      return this.isEditing ? 'Edit Subscription Plan' : 'Add Subscription Plan'
    },
    actionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
    billingTypeChoice: function() {
      return [
        {
          name: 'Vehicle/Month',
          value: 'VEHICLE/MONTH',
        },
        {
          name: 'Vehicle/Year',
          value: 'VEHICLE/YEAR',
        },
      ]
    },
  },
  mounted() {
    EventBus.$on(SubscriptionConfig.events.editingData, (item) => {
      console.log({ item })
      console.log(this.isEditing)
      this.item = item
      this.form = { ...this.item }
      console.log('subscription-data', this.form)
    })
    // console.log({ s: this.isEditing });
  },
  methods: {
    submit: async function() {
      this.isLoading = true

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? SubscriptionConfig.api.update(this.item.id)
        : SubscriptionConfig.api.create
      const formDataProxy = { ...this.form }
      if (this.form.billing_type === 'VEHICLE/YEAR') {
        formDataProxy.minimum_fee = 0
      }

      let data = new xMan(formDataProxy).toFormData()

      let message = this.isEditing
        ? 'Subscription plan updated successfully'
        : 'Subscription plan added successfully'

      // todo: remove logs
      console.log({ url })

      // try {
      // let response =
      await this.$http({ method, url, data })
        .then(() => {
          this.isLoading = false
          // Close the slideover
          dispatchEvent(new Event(SubscriptionConfig.events.sorToggle))
          // Refetch the indexData
          dispatchEvent(new Event(SubscriptionConfig.events.refresh))
          // Reset the form
          this.form = {}
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: message,
            },
            3000
          )
        })
        .catch((err) => {
          console.log(err)
        })

      // Resolved
      //   return response.status;
      // } catch (error) {
      //   // todo: remove logs
      //   console.log(error);
      //   // todo: show server errors
      //   this.isLoading = false;
      //   // todo: handle server errors
      //   this.errors.push("sadf");
      //   // Rejected
      //   // throw new Error(error.status);
      // }
    },
  },
}
</script>

<style scoped lang="scss">
.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
  padding: 4px 0; // 15px 0?
  & .left {
    width: 65%;
    padding-right: 0.5rem;
    font-size: 0.875rem;
  }
  & .right {
    width: 35%;
    padding-left: 0.5rem;
  }
  & .geofence-cat-left {
    font-size: 0.875rem;
    width: 65%;
  }
  & .geofence-cat-right {
    width: 35%;
  }
  @media screen and (min-width: 576px) {
    & .geofence-cat-left {
      width: 32%;
    }
    & .geofence-cat-right {
      width: 68%;
    }
  }
}
</style>
